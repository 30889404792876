(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name public.calendar.controller:CalendarCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.public.calendar')
    .controller('CalendarCtrl', CalendarCtrl);

  function CalendarCtrl($filter, championships, sports, phases, categories, genders) {
    var vm = this;
    vm.ctrlName = 'CalendarCtrl';

    vm.championships = _.cloneDeep(championships);

    vm.sports = _.map(sports, translateSport);
    vm.categories = _.map(categories, translateCategory);
    vm.genders = genders;
    vm.phases = phases;
 
     

    angular.forEach(vm.championships, function (championship) {
      championship.nameOrder = $filter('lowercase')(championship.name);
      championship.sport = findItemById(vm.sports, championship.sportId).name;
      var category = findItemById(vm.categories, championship.categoryId);
      championship.category = category.name;
      championship.categoryOrder = category.startYear;
      championship.gender = $filter('translate')(findItemById(vm.genders, championship.genderId).name);
      var phase = findItemById(vm.phases, championship.phaseId);
      championship.phase = phase.name;
      championship.phaseOrder = phase.iniDate;
    });

    vm.filteredChampionships = _.cloneDeep(vm.championships);


    vm.filteredChampionshipsRef = vm.filteredChampionships;

    vm.data = {
      title: 'CHAMPIONSHIPS.SELECT',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'name', name: 'CHAMPIONSHIPS.NAME', canOrder: true, orderBy: 'nameOrder'},
        {id: 'sport', name: 'SPORT', canOrder: true},
        {id: 'category', name: 'CATEGORY', canOrder: true, orderBy: 'categoryOrder', orderDesc: true},
        {id: 'gender', name: 'GENDER', canOrder: true},
        {id: 'phase', name: 'PHASES.PHASE', canOrder: true, orderBy: 'phaseOrder'}
      ],
      state: {
        slink: 'public.championshipCalendar', params: [
          {key: 'championshipId', value: 'id'}
        ]
      },
      filters: [
        {
          label: 'PHASES.SELECT',
          showField: 'name',
          filterParam: 'phaseId',
          filterField: 'id',
          options: vm.phases,
          selectedItem: undefined,
          eventRefreshData: filterChampionships
        },
        {
          label: 'SPORTS.SELECT',
          showField: 'name',
          filterParam: 'sportId',
          filterField: 'id',
          options: vm.sports,
          selectedItem: undefined,
          eventRefreshData: filterChampionships
        },
        {
          label: 'CATEGORIES.SELECT',
          showField: 'name',
          filterParam: 'categoryId',
          filterField: 'id',
          options: vm.categories,
          selectedItem: undefined,
          eventRefreshData: filterChampionships
        }
      ],
      //defaultOrderBy: ['phaseOrder', 'sport', 'categoryOrder', 'gender'],
      //defaultTypeOrderBy: [true, true, false, true],
      defaultOrderBy: ['phaseOrder', 'sport', 'categoryOrder', 'nameOrder'],
      defaultTypeOrderBy: [false, true, false, true],
      values: vm.filteredChampionships
    };

    function filterChampionships() {
      var filterObj = {};
      angular.forEach(vm.data.filters, function (filter) {
        if (hasValue(filter.selectedItem)) {
          filterObj[filter.filterParam] = filter.selectedItem[filter.filterField];
        }
      });

      return _.filter(vm.championships, filterObj);
    }

    function hasValue(field) {
      return angular.isDefined(field) && field !== null;
    }

    function translateSport(sport) {
      sport.name = $filter('translate')('SPORTS.' + sport.name);
      return sport;
    }

    function translateCategory(category) {
      category.name = $filter('translate')('CATEGORIES.' + category.name);
      return category;
    }

    function findItemById(collection, itemId) {
      return _.find(collection, function (i) {
        return i.id === itemId;
      });
    }
  }
}());
